<template>
  <div class="d-flex flex-column align-items-center" style="padding: 0px !important;">
    <div v-if="loading" class="d-flex justify-content-center mb-8 mt-8">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <form style="width: 100%;" id="payment-form" @submit.prevent="handleSubmit">
      <div id="payment-element"><!-- Stripe Payment Element --></div>
      <div class="card-errors" role="alert">{{ errorMessage }}</div>

      <b-button class="mt-16" v-if="local_order && clientSecret"
        variant="primary" block
        :disabled="spinning"
        @click.prevent="handleSubmit"
      >
        <b-spinner v-if="spinning" small type="grow"></b-spinner>
        {{ $t('SHOP_ORDER.PAY_NOW') }}
      </b-button>
    </form>
  
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_domain_url } from '@/core/services/fileDownload';

export default {
  name: 'StripePaymentComponent',
  props: ['order'],
  emits: ['update', 'start_polling'],
  mixins: [ toasts ],
  components: {

  },
  computed: {
    is_mobile() {
      return is_mobile();
    },
    callback_url() {
      return get_domain_url() + `/return-all-payment/${this.local_order.token}/${this.local_order.shop_order_id}`;
    }
  },
  data() {
    return {
      errorMessage: null,
      local_order: null,
      stripe: null,
      spinning: false,
      interval_id: null,
      clientSecret: null,
      elements: null,
      loading: true,  // True until we've mounted the Payment Element
    };
  },
  async mounted() {
    if (this.order) {
      this.local_order = { ...this.order };
    }

    await this.start_stripe();
  },
  watch: {
    order: {
      deep: true,
      handler(val) {
        this.local_order = { ...this.order };
      }
    }
  },
  methods: {
    async start_stripe() {
      try {
        const res = await axios.post(`/stripe/subscriptionintent/${this.local_order.token}/${this.local_order.shop_order_id}`);

        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed to retrieve payment details from server.');
        }

        // Extract the client_secret and publishable key from the response
        this.clientSecret = res.data.stripe_client_secret;
        const publishableKey = res.data.stripe_pk;

        // Load Stripe with the retrieved publishable key
        this.stripe = await loadStripe(publishableKey);

        // Initialize Stripe Elements with the client secret
        this.elements = this.stripe.elements({ 
          clientSecret: this.clientSecret,
          locale: 'sv'
        });

        // Create and mount the Payment Element
        const paymentElement = this.elements.create('payment');
        paymentElement.mount('#payment-element');

        // Once mounted, no longer loading
        this.loading = false;

      } catch (error) {
        console.error('Error in start_stripe:', error);
        this.errorMessage = error.message;
        this.loading = false;
      }
    },

    async handleSubmit() {
      try {
        this.spinning = true;

        // Construct return_url from local_order
        const baseUrl = window.location.origin || '';
        const returnUrl = `${baseUrl}/return-all-payment/${this.local_order.token}/${this.local_order.shop_order_id}`;

        // Confirm payment using the Payment Element
        const { error } = await this.stripe.confirmPayment({
          elements: this.elements,
          confirmParams: {
            return_url: returnUrl
          }
        });

        if (error) {
          // Immediate error
          this.errorMessage = error.message;
          console.error('Payment confirmation error:', error);
          this.spinning = false;
          return;
        }

        // If no immediate error, Stripe redirects the customer to the return_url.
        // Polling event can continue.
        this.$emit('start_polling', true);

      } catch (err) {
        console.error('handleSubmit error:', err);
      }

      this.spinning = false;
    }
  }
};
</script>

<style scoped>
.card-errors {
  color: #fa755a;
  margin-top: 10px;
}
</style>
